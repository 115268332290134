<template>
  <div class="home">
    <!-- Header start -->
    <main class="lg:relative">
      <div
        class="mx-auto w-full max-w-7xl pt-16 pb-20 text-center lg:py-48 lg:text-left"
      >
        <div class="px-4 sm:px-8 lg:w-1/2 xl:pr-16">
          <h1
            class="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl"
          >
            <span class="block xl:inline">邁向羅浮之路</span>
            <br />
            <span class="block text-purple-600 xl:inline">Keep Rovering</span>
          </h1>
          <p
            class="mx-auto mt-3 max-w-md text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl"
          >
            本平台旨在協助大學新鮮人媒合所在院校或周遭的大專院校羅浮群。
          </p>
          <p
            class="mx-auto mt-3 max-w-md text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl"
          >
            如果你願意在大學生涯中，開始你不一樣的羅浮之路，非常歡迎你填寫本表單，我們會協助將你的聯絡資訊彙整給到你所在院校最靠近的羅浮群，讓他們可以聯繫你。
          </p>
          <div class="mt-10 sm:flex sm:justify-center lg:justify-start">
            <div class="rounded-md shadow">
              <button
                @click="scrollToForm"
                class="flex w-full items-center justify-center rounded-md border border-transparent bg-purple-600 px-8 py-3 text-base font-medium text-white hover:bg-purple-700 md:py-4 md:px-10 md:text-lg"
              >
                填寫資料
              </button>
            </div>
          </div>
          <p class="mt-6 text-sm leading-8 text-gray-600">
            資料處理：中華民國童軍總會羅浮暨青年委員會
          </p>
          <p class="mt-2 text-sm leading-8 text-gray-600">
            技術支援：淡江大學童軍團
          </p>
        </div>
      </div>
      <div
        class="relative h-64 w-full sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:h-full lg:w-1/2"
      >
        <img
          class="absolute inset-0 h-full w-full object-cover"
          src="/images/home-01.jpeg"
          alt=""
        />
      </div>
    </main>
    <!-- Header end -->

    <!-- 關於羅浮 section start -->
    <div class="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32">
      <img
        src="/images/home-04.jpeg"
        alt=""
        class="absolute inset-0 -z-10 h-full w-full object-cover brightness-50"
      />

      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="mx-auto max-w-2xl lg:mx-0">
          <h2
            class="text-4xl font-bold tracking-tight text-white sm:text-6xl text-left"
          >
            關於羅浮
          </h2>
          <p class="mt-6 text-lg leading-8 text-gray-300 text-left">
            依照羅浮(Rovering)的字義，我並不是指漫無目的的漫遊，我指的是在心中帶著明確的目的，以愉快的途徑找到你自己的道路，並且沿途抱持你可能遭遇的諸多困難和危險的想法。<br /><br />
            - 貝登堡爵士
          </p>
        </div>
      </div>
    </div>
    <!-- 關於羅浮 section end -->

    <!-- 給團長的一封信 Start  -->
    <div class="relative bg-white">
      <div class="lg:absolute lg:inset-0">
        <div class="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
          <img
            class="h-56 w-full object-cover lg:absolute lg:h-full"
            src="/images/home-02.jpeg"
            alt=""
          />
        </div>
      </div>
      <div
        class="relative px-4 pt-12 pb-16 sm:px-6 sm:pt-16 lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-2 lg:px-8"
      >
        <div class="lg:col-start-2 lg:pl-8">
          <div
            class="mx-auto max-w-prose text-base lg:ml-auto lg:mr-0 lg:max-w-lg"
          >
            <h2 class="font-semibold leading-6 text-purple-600">
              延續童軍生命
            </h2>
            <h3
              class="mt-6 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl"
            >
              團長您好
            </h3>
            <div class="prose prose-purple mt-5 text-gray-500 text-left">
              <p class="mb-4">
                在您的指導下，許多青少年在他們國小、國中、高中階段參加了童軍，從童軍運動中收穫了不同的經驗，豐富了自己的人生，在他們進入大學這個距離職場僅一步之遙的階段，常因為各種原因而放棄成為羅浮童軍，或僅是留在自己曾經參加的團內以學長姐的身分指導學弟妹們，雖然換了一套肩章成為羅浮童軍，卻不一定有機會實踐在大學中才能獲得的社團經營經驗。
              </p>

              <p class="mb-4">
                大學校園中的羅浮童軍除了在野外活動中精進曾經所學的童軍技能與露營技巧，在童軍運動的共同目標下持續維持與人為善、助人為樂的服務精神，藉由童軍國際化的特色廣結善緣、結交國際友人，建立跨文化的世界觀。
              </p>
              <p class="mb-4">
                更重要的是透過經營社團時學習到的人際溝通、團隊合作、專案管理、企劃能力等職前準備，並有機會透過在各行各業任職的學長姐取得更多資源與歷練，在離開校園後能順利踏入職場。
              </p>
              <p class="mb-4">
                如果您也願意鼓勵曾經帶過的團員在大學時加入各校的羅浮群，歡迎您在這個系統提供團員的就讀學校與聯絡方式，我們將會把資料彙整後提供給各校或鄰近學校的羅浮顧問，讓他們主動邀請您提供的夥伴參加羅浮群活動，並就近提供生活上的協助，讓孩子們的大學生涯更加充實，也為大學校園的羅浮群加入更多新血，感謝您！
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 給團長的一封信 End  -->
    <!-- 給未來羅浮的一封信 Start  -->
    <div class="relative bg-white">
      <div class="lg:absolute lg:inset-0">
        <div class="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <img
            class="h-56 w-full object-cover lg:absolute lg:h-full"
            src="/images/home-03.jpeg"
            alt=""
          />
        </div>
      </div>
      <div
        class="relative px-4 pt-12 pb-16 sm:px-6 sm:pt-16 lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-2 lg:px-8"
      >
        <div class="lg:col-start-1 lg:pl-8">
          <div
            class="mx-auto max-w-prose text-base lg:mr-auto lg:ml-0 lg:max-w-lg"
          >
            <h2 class="font-semibold leading-6 text-purple-600">
              開拓人生新篇章
            </h2>
            <h3
              class="mt-6 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl"
            >
              Hi 大學新鮮人
            </h3>
            <div class="prose prose-purple mt-5 text-gray-500 text-left">
              <p class="mb-4">
                來到大學校園，面對琳琅滿目的社團，是否還在猶豫該加入哪一個呢?
              </p>
              <p class="mb-4">
                你曾在國小、國中、高中階段參加了童軍，從童軍運動中收穫了不同的經驗，豐富了自己的人生，但你知道嗎？大學校園裡的羅浮童軍跟你從前的認知可能很不一樣喔！
              </p>
              <p class="mb-4">
                除了在野外活動中精進曾經所學的童軍技能與露營技巧，在童軍運動的共同目標下持續維持與人為善、助人為樂的服務精神，藉由童軍國際化的特色廣結善緣、結交國際友人，建立跨文化的世界觀。更重要的是透過經營社團時學習到的人際溝通、團隊合作、專案管理、企劃能力等職前準備，還有比別人多的機會可以透過在各行各業任職的學長姊取得社會資源與歷練，在離開校園後能順利踏入職場。
              </p>
              <p class="mb-4">
                如果您願意花一點時間了解校內或鄰近各校的羅浮群，請您在這個系統登記就讀學校與聯絡方式，我們將會把資料彙整後提供給相關的羅浮顧問，讓他們主動邀請您參加大專院校羅浮群活動，並就近提供生活上的協助，讓您的大學生涯更加充實，也為大學校園的羅浮群加入更多新血，歡迎您！
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 給未來羅浮的一封信 End -->

    <!-- Form Start -->
    <div id="rover-form-section" class="bg-gray-100">
      <div class="mx-auto max-w-7xl py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div class="relative bg-white shadow-xl">
          <div class="grid grid-cols-1 lg:grid-cols-2">
            <!-- Contact form -->
            <div class="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
              <h3 class="text-lg font-medium text-gray-900">請填寫以下資訊</h3>
              <h5 class="text-sm font-thin text-gray-600">* 為必填寫欄位</h5>
              <form
                class="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8 text-left"
                @submit="onSubmit"
              >
                <div>
                  <label
                    for="name"
                    class="block text-sm font-medium text-gray-900"
                    >姓名 *</label
                  >
                  <div class="mt-1">
                    <input
                      type="text"
                      id="name"
                      v-model="form.name"
                      class="border block w-full rounded-md border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                    />
                  </div>
                </div>
                <div>
                  <label
                    for="mobile"
                    class="block text-sm font-medium text-gray-900"
                    >手機</label
                  >
                  <div class="mt-1">
                    <input
                      type="text"
                      id="mobile"
                      v-model="form.mobile"
                      class="border block w-full rounded-md border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                    />
                  </div>
                </div>
                <div>
                  <label
                    for="email"
                    class="block text-sm font-medium text-gray-900"
                    >Email *</label
                  >
                  <div class="mt-1">
                    <input
                      id="email"
                      type="email"
                      v-model="form.email"
                      class="border block w-full rounded-md border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                    />
                  </div>
                </div>
                <div>
                  <div class="flex justify-between">
                    <label
                      for="lineid"
                      class="block text-sm font-medium text-gray-900"
                      >LINE ID</label
                    >
                  </div>
                  <div class="mt-1">
                    <input
                      type="text"
                      id="lineid"
                      v-model="form.lineid"
                      class="border block w-full rounded-md border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                    />
                  </div>
                </div>
                <div>
                  <div class="flex justify-between">
                    <label
                      for="city"
                      class="block text-sm font-medium text-gray-900"
                      >學校所在縣市 *</label
                    >
                  </div>
                  <div class="mt-1">
                    <select
                      id="city"
                      class="border block w-full rounded-md border-gray-300 py-3 pl-3 pr-10 text-gray-900 shadow-sm focus:border-purple-500 focus:ring-indigo-500"
                      v-model="form.city"
                    >
                      <option v-for="(city, key) in collegeCities" :key="key">
                        {{ city }}
                      </option>
                    </select>
                  </div>
                </div>
                <div>
                  <div class="flex justify-between">
                    <label
                      for="college"
                      class="block text-sm font-medium text-gray-900"
                      >學校 *</label
                    >
                  </div>
                  <div class="mt-1">
                    <select
                      id="college"
                      type="text"
                      class="border block w-full rounded-md border-gray-300 py-3 pl-3 pr-10 text-gray-900 shadow-sm focus:border-purple-500 focus:ring-indigo-500"
                      v-model="form.college"
                    >
                      <option v-for="(college, key) in colleges" :key="key">
                        {{ college.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div>
                  <div class="flex justify-between">
                    <label
                      for="applicant"
                      class="block text-sm font-medium text-gray-900"
                      >填寫人</label
                    >
                  </div>
                  <div class="mt-1">
                    <select
                      id="applicant"
                      class="border block w-full rounded-md border-gray-300 py-3 pl-3 pr-10 text-gray-900 shadow-sm focus:border-purple-500 focus:ring-indigo-500"
                      v-model="form.applicant"
                    >
                      <option>本人</option>
                      <option>團長</option>
                    </select>
                  </div>
                </div>
                <div>
                  <label
                    for="major"
                    class="block text-sm font-medium text-gray-900"
                    >目前就讀科系/年級</label
                  >
                  <div class="mt-1">
                    <input
                      id="major"
                      v-model="form.major"
                      type="text"
                      class="border block w-full rounded-md border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                    />
                  </div>
                </div>
                <div>
                  <fieldset class="space-y-5">
                    <legend class="text-sm font-medium text-gray-900">
                      童軍經驗
                    </legend>
                    <div class="relative flex items-start">
                      <div class="flex h-5 items-center">
                        <input
                          id="scout-exp-1"
                          type="checkbox"
                          v-model="form.experience"
                          value="幼童軍"
                          class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        />
                      </div>
                      <div class="ml-3 text-sm">
                        <label
                          for="scout-exp-1"
                          class="font-medium text-gray-700"
                          >幼童軍</label
                        >
                      </div>
                    </div>
                    <div class="relative flex items-start">
                      <div class="flex h-5 items-center">
                        <input
                          id="scout-exp-2"
                          type="checkbox"
                          v-model="form.experience"
                          value="童軍"
                          class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        />
                      </div>
                      <div class="ml-3 text-sm">
                        <label
                          for="scout-exp-2"
                          class="font-medium text-gray-700"
                          >童軍</label
                        >
                      </div>
                    </div>
                    <div class="relative flex items-start">
                      <div class="flex h-5 items-center">
                        <input
                          id="scout-exp-3"
                          type="checkbox"
                          v-model="form.experience"
                          value="行義童軍"
                          class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        />
                      </div>
                      <div class="ml-3 text-sm">
                        <label
                          for="scout-exp-3"
                          class="font-medium text-gray-700"
                          >行義童軍</label
                        >
                      </div>
                    </div>
                    <div class="relative flex items-start">
                      <div class="flex h-5 items-center">
                        <input
                          id="scout-exp-4"
                          type="checkbox"
                          v-model="form.experience"
                          value="羅浮童軍"
                          class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        />
                      </div>
                      <div class="ml-3 text-sm">
                        <label
                          for="scout-exp-4"
                          class="font-medium text-gray-700"
                          >羅浮童軍</label
                        >
                      </div>
                    </div>
                  </fieldset>
                </div>
                <div class="sm:col-span-2">
                  <div class="flex justify-between">
                    <label
                      for="message"
                      class="block text-sm font-medium text-gray-900"
                      >備註訊息</label
                    >
                    <span id="message-max" class="text-sm text-gray-500"
                      >最多 500 字元</span
                    >
                  </div>
                  <div class="mt-1">
                    <textarea
                      id="message"
                      name="message"
                      rows="4"
                      class="border block w-full rounded-md border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                      aria-describedby="message-max"
                      v-model="form.message"
                      maxlength="500"
                    />
                  </div>
                </div>
                <div class="sm:col-span-2">
                  <div class="flex gap-3">
                    <div class="flex h-5 items-center">
                      <input
                        id="aggrement"
                        type="checkbox"
                        value="同意"
                        v-model="form.aggrement"
                        class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                    </div>
                    <div class="text-sm">
                      <label for="aggrement" class="text-gray-700"
                        >*
                        我同意提供我的個人資料，用於本平台聯絡使用，不得作為其他用途。
                      </label>
                    </div>
                  </div>
                </div>
                <div class="sm:col-span-2 sm:flex sm:justify-end">
                  <button
                    class="mt-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-purple-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 sm:w-auto disabled:opacity-25"
                    @click.prevent="submitForm"
                    :disabled="loading"
                  >
                    送出
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Form End -->

    <!-- alert modal start -->
    <TransitionRoot as="template" :show="open !== false">
      <Dialog as="div" class="relative z-10" @close="open = false">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div
            class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
          >
            <TransitionChild
              as="template"
              enter="ease-out duration-300"
              enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enter-to="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leave-from="opacity-100 translate-y-0 sm:scale-100"
              leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6"
              >
                <div>
                  <div
                    v-if="open == 'success'"
                    class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100"
                  >
                    <CheckIcon
                      class="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div
                    v-if="open == 'error'"
                    class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100"
                  >
                    <XMarkIcon
                      class="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div class="mt-3 text-center sm:mt-5">
                    <DialogTitle
                      as="h3"
                      class="text-base font-semibold leading-6 text-gray-900"
                      >{{
                        open === "success" ? "送出成功" : "錯誤"
                      }}</DialogTitle
                    >
                    <div class="mt-2">
                      <p class="text-sm text-gray-500">
                        {{
                          open === "success"
                            ? "我們收到資料後會盡快安排，感謝您支持羅浮活動"
                            : "您有欄位尚未填寫完畢"
                        }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="mt-5 sm:mt-6">
                  <button
                    type="button"
                    class="inline-flex w-full justify-center rounded-md bg-purple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-500"
                    @click="open = false"
                  >
                    關閉
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <!-- alert modal end -->
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from "@headlessui/vue";
import { CheckIcon, XMarkIcon } from "@heroicons/vue/24/outline";
import allColleges from "@/resources/college.json";
import axios from "axios";

const open = ref(false);
const loading = ref(false);

const initialForm = {
  name: null,
  mobile: null,
  email: null,
  city: null,
  college: null,
  applicant: null,
  experience: [],
  message: null,
  aggrement: null
};

const form = ref(Object.assign({}, initialForm));

const collegeCities = computed(() => {
  let cities = [];

  for (let i = 0; i < allColleges.length; i++) {
    if (!cities.find((city) => city == allColleges[i].city)) {
      cities.push(allColleges[i].city);
    }
  }

  return cities;
});

const colleges = computed(() => {
  let filteredColleges = allColleges.filter(
    (college) => college.city === form.value.city
  );
  return [{ name: "高中職學生" }, ...filteredColleges];
});

const scrollToForm = () => {
  let formElement = document.getElementById("rover-form-section");
  formElement.scrollIntoView({ behavior: "smooth" });
};

const submitForm = async () => {
  let requiredFields = ["name", "email", "city", "college", "aggrement"];

  for (const field of requiredFields) {
    if (!form.value[field]) {
      open.value = "error";
      return;
    }
  }

  try {
    loading.value = true;

    await axios.post(
      `https://keep-rovering-api.scout.tw/keep-rovering/submit`,
      {
        ...form.value,
        experience:
          form.value.experience.length > 0
            ? form.value.experience.join(",")
            : null
      }
    );

    const entries = Object.entries(initialForm);
    for (const [key, value] of entries) {
      form.value[key] = value;
    }

    open.value = "success";
  } catch (error) {
    console.error(error);
    open.value = "error";
  } finally {
    loading.value = false;
  }
};
</script>
